<template lang="pug">
    .contact-form#contact
        v-container.pa-2.pa-md-5
            h2.text-center.my-3.mb-6 Contact Dima
            h3.text-center Got a question, notice a bug, or got a web app idea you'd like built? You can get in touch.
            p.text-center 
                small Prefer email? You can also reach me at <a href="mailto:dima@mvpforsaas.com">dima@MvpForSaas.com</a>.
            
            v-card.pa-5(color="grey lighten-5").my-6
                v-form(ref="form", accept-charset="UTF-8", v-model="valid", name="contact-form", method="POST" action="https://formsubmit.co/dima@mvpforsaas.com", @submit="handleSubmit")

                    v-container
                        input(type="hidden", name="_next" value="https://bgproperty.today/thanks")
                        input(type="hidden", name="_subject" value="Your message to Dima on bgProperty.today")
                        input(type="hidden", name="_captcha" value="false")
                        input.d-none(type="text", name="_honey")
                        v-row
                            v-col(cols="12" md="6")
                                v-text-field(name="name", v-model="name", placeholder="John Smith", label="Your Name" outlined, :rules="nameRules")
                            v-col(cols="12" md="6")
                                v-text-field(name="email", type="email", v-model="email", placeholder="your.name@example.com" label="Email address" outlined, :rules="emailRules")
                        v-row
                            v-col(cols="12" md="12")
                                v-textarea(name="message", v-model="message", outlined placeholder="Type your message here..." label="Your Message", :rules="messageRules")
                        v-row
                            v-col(cols="12")
                                v-btn(x-large :color="valid ? 'success' : 'primary'",  :disabled="!valid" :loading="loading" :block="$vuetify.breakpoint.smAndDown" @click="submit")
                                    | Send Message
                                    v-icon.ml-2 mdi-email-check
</template>

<script>
import { isValidEmail } from "@/helpers";
export default {
  name: "ContactForm",
  data() {
    return {
      loading: false,
      name: "",
      email: "",
      message: "",
      valid: false,
      nameRules: [v => !!v || "Name is required"],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          isValidEmail(v) ||
          "Please double-check your email address to ensure it's valid."
      ],
      messageRules: [v => !!v || "A message is required"]
    };
  },
  computed: {},
  methods: {
    submit() {
      this.loading = true;
      if (this.valid) {
        this.$refs.form.$el.submit();
      }
    },
    handleSubmit(e) {
      console.log("handling form submit e", e);
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-form {
  border-top: 2px solid rgba(255, 255, 255, 0.112);
  background: white;
  box-shadow: 0px -15px 25px rgba(0, 0, 0, 0.05);
}
</style>
